.global-customer-service {
    &.open {
        .ant-float-btn-group-wrap {
            .ant-float-btn-icon {
                display: block;
            }
        }

        .ant-float-btn-icon {
            display: none;
        }
    }
}

.quota-content {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;

    .image {
        width: 150px;
        height: 150px;

        img {
            width: 150px;
            height: 150px;
        }
    }

    .description {
        font-size: 14px;
        padding-top: 16px;
    }
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

.mainapp {
    width: 100%;
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    height: 100vh;
}

.mainapp-main {
    flex: 1 1 auto;
    min-height: 450px;
}

.mainapp-loading {
    color: #444;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

.global-customer-service {
    width: 60px;
    transition: transform 0.3s;
    right: 24;

    > button {
        height: 60px;
        width: 60px;

        &:hover {
            transform: scale(1.05);
        }
    }

    .ant-float-btn-group-wrap {
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}

::-webkit-scrollbar {
    width: 10px;
    height: 10px;
}

/*定义滚动条轨道内阴影+圆角*/
::-webkit-scrollbar-track {
    background: transparent;
    box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}

/*定义滑块内阴影+圆角*/
::-webkit-scrollbar-thumb {
    background: #dddddd;
    border-radius: 10px;
    box-shadow: inset 0 0 5px rgba(0, 21, 41, 0.05);
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

@header-color: #c0c4cc;

.page-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 48px;
    line-height: 48px;
    padding: 0 24px 0 0;
    background: #222c40;
    border-bottom: 1px solid #777777;
    position: sticky;
    top: 0;
    left: 0;
    z-index: 100;
    color: @header-color;

    .left {
        display: flex;
        align-items: center;

        .logo {
            height: 28px;
            margin: 0 4px 0 20px;
            border: 0px !important;
        }

        .system-name {
            font-size: 20px;
            font-weight: 500;
            color: #ffffff;
            text-decoration: none;
        }

        .horizontal-menu-content {
            padding: 0 84px;

            .menu-item {
                font-size: 16px;
            }
        }
    }

    .content {
        color: #00a0ff;
        text-decoration: underline;
        padding: 0px 20px;
        list-style: none;
        font-size: 20px;
    }

    .right {
        display: flex;
        align-items: center;
        gap: 10px;
        font-size: 14px;

        svg {
            font-size: 20px;
        }

        .item {
            cursor: pointer;
            color: @header-color;
            text-decoration: none;
        }

        .item-line {
            height: 16px;
            border-color: @header-color;
        }

        .user-info {
            .default-content {
                min-width: 72px;

                .icon-item {
                    margin-right: 8px;
                }
            }
        }
    }
}

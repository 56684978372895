.menu-container {
    display: flex;
    justify-content: center;
    align-items: center;

    .op-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 24px;
        cursor: pointer;
        background: #606266;
        height: 48px;
        width: 64px;
        color: #191919;
    }
}

.menu-drawer {
    z-index: 2000;

    .ant-drawer-mask {
        background: rgba(255, 255, 255, 0) !important;
    }

    .ant-drawer-content-wrapper {
        top: 49px !important;

        .ant-drawer-content {
            background: #222c40;
            color: #ffffff;

            .ant-drawer-wrapper-body {
                .ant-drawer-header {
                    border: 0;

                    .ant-drawer-header-title {
                        flex-direction: row-reverse;

                        .ant-drawer-close {
                            font-size: 18px;
                            margin: 0;
                            padding: 0;
                            color: #ffffff;
                        }
                    }
                }

                .ant-drawer-body {
                    padding: 16px 24px;

                    ::-webkit-scrollbar-thumb {
                        background: #fff;
                    }
                }
            }
        }
    }

    .menu-content {
        column-count: 3;
        column-gap: var(--nav-grid-column-gap);
        color: #ffffff;

        .menu-item-wrap {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            break-inside: avoid;
            margin-bottom: 40px;

            .menu-item-title {
                font-size: 14px;
                font-weight: 600;
                text-align: left;
                text-decoration: none;
                display: block;
                margin-bottom: 14px;
            }

            .menu-item {
                display: flex;
                align-items: center;
                text-align: left;
                line-height: 20px;
                font-size: 12px;
                color: #ffffff;
                text-decoration: none;
                padding: 6px 0;

                a {
                    color: #ffffff;
                }
            }
        }
    }
}

/** element */
.el-menu--horizontal {
    border-bottom: 0px !important;
}

.el-menu {
    .el-sub-menu.is-active .el-sub-menu__title {
        border-bottom-color: var(--el-menu-active-color);
        color: #18b3b3;
    }

    .el-menu-item.is-active {
        background-color: #e1f2ef;
    }
}

.el-breadcrumb__inner a,
.el-breadcrumb__inner.is-link {
    font-weight: 500 !important;
}

.el-table {
    thead {
        color: #667d98 !important;
    }

    .el-table__header {
        tr,
        th.el-table__cell {
            background-color: #fafafa !important;
            padding: 12px 0;
        }
    }

    tr {
        .el-table__cell {
            padding: 12px 0;
        }
    }
}

.el-dialog__headerbtn .el-dialog__close {
    color: var(--el-text-color-primary) !important;
}

/** antd */
.ant-table {
    .ant-table-header {
        border-radius: 0px !important;
    }

    .ant-table-thead tr th {
        background-color: #fafafa !important;
        border-radius: 0px !important;
    }

    .ant-table-tbody > tr.ant-table-row:hover > td {
        background-color: #fafafa !important;
    }
}

.ant-modal {
    .ant-modal-close-x {
        font-size: 20px;
    }

    .ant-modal-header {
        margin-bottom: 20px !important;
    }
}

.ant-btn-default:not(:disabled):not(.ant-btn-disabled) {
    &:hover,
    &:active {
        color: #18b3b3 !important;
        border-color: #18b3b3 !important;
    }
}

.ant-btn:not(:disabled):focus-visible {
    outline: 0px solid #ffffff !important;
    outline-offset: 1px;
    transition: outline-offset 0s, outline 0s;
}

.ant-btn-primary {
    &:not(:disabled):not(.ant-btn-disabled) {
        background-color: #18b3b3 !important;
        box-shadow: none !important;

        &:hover {
            background-color: #37bfbb !important;
        }

        &:active {
            background-color: #0b888c !important;
        }
    }
}

/** 业务 */
.app-container > .el-card,
.page-card,
.page-card-border {
    border: 0px !important;
}

.page-card {
    min-height: calc(100vh - 115px);
    border: 0 !important;
    border-radius: 16px !important;
    box-shadow: rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0) 0px 0px 0px 0px, rgba(0, 0, 0, 0.03) 0px 0px 10px 0px !important;
}

.page-card-padding {
    .el-card__header {
        padding-left: 0px;
        padding-right: 0px;
    }

    .el-card__body {
        padding: 0px;
    }
}
